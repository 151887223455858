<template>
  <div class="myNew">
    <h3 id="title">{{ article.post_title }}</h3>
    <div id="metaContent">
      <p><span id="zuozhe">中国教育会计学会高职分会</span> <span id="pushedTime">{{ article.published_time | pushedTime}}</span></p>
    </div>
    <div class="newContent" >
      <video :src="article.videoUrl" width="100%"  controls="controls"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import vData from "@/views/vdata";

export default {
  name: "NewPage",
  components: {},
  data() {
    return {
      // 新闻列表
      article: {},
      id: 0,
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getArticles()
  },
  filters: {
    pushedTime(data) {
      return moment(data).format('YYYY-MM-DD')
    }
  },
  methods: {
    getArticles() {
      let dd = vData
      console.log(dd)
      this.article = dd[this.id]

     /* this.$API.news.articles(this.id).then(res => {
        this.article = res.data
      })*/
    }
  }
}
</script>

<style scoped>
.myNew {
  margin: 30px;
}

#title {
  font-size: 40px;
}

.newContent {
//width: 100%;
 overflow: hidden;
  word-wrap: break-word;
  text-align: justify;
  hyphens: auto
}
.newContent >>> img{
  max-width:100%;
}
#metaContent {
  font-size: 30px;
  margin: 40px 0;
}

#zuozhe {
  color: rgba(0, 0, 0, 0.3);

}

#pushedTime {
  color: rgba(0, 0, 0, 0.3);
  margin: 0 20px;
}
</style>
